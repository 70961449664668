<template>
  <div>
    <!-- PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>
<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BTable, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
// import useUsersList from '../users-list/useUsersList'
export default {
  components: {

    BButton,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,

  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]
    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]
    return {
      roleOptions,
      statusOptions,
      permissionsData,
    }
  },
  data() {
    return {
      username: '',
      repository: '',
      repositories: [],
      role: {},
      states: ['Ouverte', 'Clôturée'],
    }
  },
  created() {
    this.getRoles()
  },
  methods: {
    async getRoles() {
      // @ts-ignore
      const { id } = this.$route.params
      await axios.get(`/api/authentication/groups/detail/${id}`).then(response => {
        this.role = response.data
      })
    },
    async editAcademicYears() {
      await axios
        .put(
          `/api/academic-years/update/${this.academicYear.id}/`,
          {
            academic_year: this.academicYear.academic_year,
            state: this.academicYear.state,
            start_date: this.academicYear.start_date,
            end_date: this.academicYear.end_date,
            principal_deliberation: this.academicYear.principal_deliberation,
            control_deliberation: this.academicYear.control_deliberation,
          },
        )
        .then(() => {
          this.$router.push('/academic-years/')
          setTimeout(() => {
            this.showToast('success', 'top-center', 'Année modifié avec succés')
          }, 1000)
        })
        .catch(err => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', err.toString())
          }, 1000)
        })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
